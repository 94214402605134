<template>
  <div class="authentication_component">
    <div class="auth_right login_block setPassword_block">
      <template v-if="accountExistLoader">
        <beat-loader :color="'#436aff'"></beat-loader>
      </template>
      <template v-else>
        <router-link :to="{ name: 'login' }">
          <button type="button" class="close">×</button>
        </router-link>
        <div class="d-flex align-items-center height" style="overflow: auto">
          <div class="_wrapper m-auto">
            <div class="right_logo d-block">
              <img class="!w-[13.875rem]" src="../../modules/account/assets/img/logo/contentstudio-logo-original.png" alt="ContentStudio"
              />
            </div>
            <div class="auth_right_inner">
              <div class="top_block mt-5 container">
                <h2 class="!text-[1rem]">Welcome! This is where creativity and collaboration flourish.</h2>
                <p class="!text-[0.9rem] leading-6 text-gray-800 px-10">To ensure security and accessibility of your account, kindly set your password and provide additional details to continue.</p>
              </div>

              <div class="mt-10 container mb-5 px-20">

                <CstFloatingLabelInput
                    id="email"
                    v-model="account.email"
                    class="mb-6"
                    type="email"
                    label="Your email address"
                    show-icon-left
                    value="email"
                    :disabled="emailDisabled"
                    >
                    <template v-slot:icon>
                      <i class="icon-Email"></i>
                    </template>
                </CstFloatingLabelInput>

                <!-- Password -->
                <div class="mb-6">
                  <CstFloatingLabelInput
                    id="password"
                    v-model="account.password"
                    class="!h-[3.275rem]"
                    type="password"
                    label="Password"
                    :maxlength="18"
                    show-icon-left
                    value="account.password">
                    <template v-slot:icon>
                      <i class="icon-Password"></i>
                    </template>
                  </CstFloatingLabelInput>

                  <div v-if="password_strength_message" class="simple-form__text-field-message flex items-center justify-between mx-1 relative top-[1.11rem]" :class="{ danger: password_state === 'danger' }">
                  <p
                      class="text-xs"
                  >
                    {{ password_strength_message }}
                  </p>
                  <div class="flex gap-1">
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-red-500': password_state === 'weak', ' !bg-green-500': password_state === 'strong', '!bg-yellow-500': password_state === 'fair' }"></div>
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-green-500': password_state === 'strong', 'bg-yellow-500': password_state === 'fair' }"></div>
                    <div class="w-3 h-1.5 rounded-full bg-gray-600" :class="{ '!bg-green-500': password_state === 'strong' }"></div>
                  </div>
                  </div>
                </div>

                <!-- Business Name -->
                <CstFloatingLabelInput
                  id="bName"
                  v-model="account.business_name"
                  class="!h-[3.275rem] mb-6"
                  type="text"
                  label="Business Name"
                  show-icon-left
                  value="account.business_name">
                  <template v-slot:icon>
                    <i class="far fa-briefcase"></i>
                  </template>
                </CstFloatingLabelInput>

                <!-- Business Type -->

                <div
                  class="
                    simple-form__select-field
                    flex
                    items-center
                    h-[3.275rem]
                    rounded-md
                    border
                    bg-white
                    text-gray-900 text-sm
                    focus-within:outline-blue
                    w-full
                    mb-6
                    py-2.5
                    px-0
                  "
                >
                <select
                  v-model="account.business_type"
                  class="!px-3"
                  required
                  data-cy="business_type"
                >
                  <option disabled selected value=""
                    >What best describes you?</option
                  >
                  onkeypress="return
                  /\d/.test(String.fromCharCode(((event||window.event).which||(event||window.event).which)));"
                  <option value="blogger">Blogger</option>
                  <option value="solopreneur">Solopreneur</option>
                  <option value="agency">Agency</option>
                  <option value="saas_business">SaaS Business</option>
                  <option value="marketing_team">Marketing Team</option>
                  <option value="enterprise">Enterprise</option>
                </select>
              </div>

              <button
                  data-cy="continue_button"
                  class="btn btn-studio-theme-space flex justify-center btn-size-large w-full"
                  @click.prevent="executeRecaptcha"
              >
                <span>Continue</span>
                <img
                    v-if="registerLoader"
                    style="width: 20px; margin-left: 8px"
                    src="../../assets/img/common/gif_loader_white.gif"
                    alt=""
                />
              </button>

              </div>


               <!-- Google Recaptcha-->
               <vueRecaptcha
                        ref="recaptcha"
                        :sitekey="getGoogleRecaptchaKey"
                        size="invisible"
                        theme="light"
                        loading-timeout="30000"
                        @verify="createAccount"
                        @expire="onCaptchaExpired"
                        @fail="onCaptchaExpired"
                        @error="onCaptchaExpired">
                    </vueRecaptcha>

            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {workspaceTypes} from '@src/modules/setting/store/states/mutation-types'
import {authenticationTypes} from '@state/mutation-types'
import {accountExistURL, registerURL} from '@src/config/api-utils.js'
import vueRecaptcha from "vue3-recaptcha2";
import CstFloatingLabelInput from '@ui/Input/CstFloatingLabelInput'
import useTime from "@/src/modules/common/composables/useTime"
import useIp from "@/src/modules/common/composables/useIp"

export default {
  components: {
    vueRecaptcha,
    CstFloatingLabelInput,
  },
  setup() {
    const { getClientTimeZone } = useTime()
    const { ipv4 } = useIp()

    return {
      getClientTimeZone,
      ipv4
    }
  },
  data() {
    return {
      emailDisabled: false,
      account: {
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        plan: 'trial',
        business_type: '',
        picture: '',
        id: '',
        type: '',
        timezone: this.getClientTimeZone(),
      },
      size: '14px',
      loaderColor: '#fff',
      registerLoader: false,

      email: '',
      password: '',
      cpassword: '',
      accountExistLoader: false,
      password_strength_message: '',
      password_state: '',
    }
  },
  computed: {
    ...mapGetters([]),

    getGoogleRecaptchaKey() {
      return process.env.VUE_APP_GOOGLE_RECAPTCHA_KEY
    },
  },
  watch: {
    'account.password'(value) {
      if (value) {
        if (value.length === 0) {
          this.password_strength_message = ''
          this.password_state = ''
        }
        if (value.length < 12) {
          this.password_strength_message = 'Password must be 12 characters or more';
          this.password_state = 'danger';
        } else if (value.length < 14) {
          this.password_strength_message = 'Password could be more secure';
          this.password_state = 'weak';
        } else if (value.length < 16) {
          this.password_strength_message = 'Password is okay';
          this.password_state = 'fair';
        } else {
          this.password_strength_message = 'Password is strong';
          this.password_state = 'strong';
        }
      } else {
        this.password_strength_message = ''
        this.password_state = ''
      }
    },
  },
  mounted() {},
  created() {
    this.accountExists()
  },
  methods: {
    ...mapMutations(['SET_PROFILE']),
    ...mapActions(['fetchProfile']),

    async accountExists() {
      this.accountExistLoader = true
      const res = await this.$http
        .post(accountExistURL, {
          id: this.$route.query.id,
          type: this.$route.query.type,
          email: this.$route.query.email,
        })
        .then(
          async (response) => {
            if (response.data.status) {
              // login to the account and redirect
              if (
                response.data.user &&
                response.data.user.status &&
                response.data.user.status === 'invited'
              ) {
                this.alertMessage(response.data.message, 'error')
                this.$router.push({ name: 'login' })
                return false
              }

              // check if user has enabled 2FA
              if (response.data['2fa_enabled']) {
                await this.$router.push({
                  name: 'twoFactor',
                  params: {
                    token: response.data.user_info,
                  },
                  query: {
                    ...this.$route.query,
                    redirected_url: this.$route.fullPath,
                  },
                })
                return
              }

              this.$store.commit(
                authenticationTypes.SET_JWT_TOKEN,
                response.data.token
              )
              this.$store.commit(
                authenticationTypes.SET_LOGGED_USER,
                response.data.logged_user
              )
              this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)
              if (this.getProfile.onBoarding) {
                this.isUserOnboarded()
              } else {
                if (response.data.user) {
                  this.SET_PROFILE(response.data.user)
                }
                this.loginLoader = false
              }
              return response
            } else {
              if (this.$route.query.email) {
                this.emailDisabled = true
              }
              this.account.type = this.$route.query.type
              this.account.id = this.$route.query.id
              this.account.email = this.$route.query.email
              this.account.firstname = this.$route.query.first_name
              this.account.lastname = this.$route.query.last_name
              this.account.picture = this.$route.query.picture
              this.accountExistLoader = false
            }
            return response
          },
          (response) => {
            return null
            // this.accountExistLoader = false
          }
        )
      console.log('accountExists res > ', res)
      if (res && res.data.status) {
        if (
          res.data.user.state &&
          (res.data.user.state === 'canceled' ||
            res.data.user.state === 'cancelled')
        ) {
          this.$router.push({ name: 'subscription_cancelled' })
          return
        }

        if (res.data.user.state && res.data.user.state === 'deleted') {
          this.$router.push({ name: 'subscription_deleted' })
          return
        }

        if (res.data.user.state && res.data.user.state === 'paused') {
          this.$router.push({ name: 'subscription_paused' })
          return
        }

        if (res.data.user.trial_finished) {
          this.$router.push({ name: 'trial_expired' })
          return
        }
        try {
          await this.fetchWorkspaces()
          this.Plan()
          if (res.data.activeWorkspace) {
            this.$store.commit(
                workspaceTypes.SET_ACTIVE_WORKSPACE,
                res.data.activeWorkspace.workspace
            )
            this.resetDefaultStates()
            this.initializeSection()
            // await this.fetchDiscover()
            this.fetchSocialAccounts()
            this.$router.push({
              name: res.data?.user?.preferences?.default_landing_page || 'dashboard',
              params: {workspace: res.data.activeWorkspace.workspace.slug},
            })
          } else {
            console.debug('Login: Redirecting to workspaces')
            this.$router.push({name: 'workspaces'})
          }
        } catch (e) {
          console.error('Login: Error while fetching workspaces', e)
          this.$router.push({name: 'workspaces'})
        }
      }
    },
    executeRecaptcha() {
      const validate = this.validateRegisterForm()
      if(validate) this.$refs.recaptcha.execute()
    },
    onCaptchaExpired() {
      this.registerLoader = false
      this.$refs.recaptcha.reset()
    },

    async createAccount(recaptchaToken) {
      this.registerLoader = true
      this.account.captcha_code = recaptchaToken
      this.$refs.recaptcha.reset()
      const response = await this.$http
          .post(registerURL, {...this.account, ip: this.ipv4})
          .then((response) => {
            this.registerLoader = false
            if (response.data.status) {
              this.$store.commit(
                  authenticationTypes.SET_JWT_TOKEN,
                  response.data.token
              )
              this.$store.commit(
                  authenticationTypes.SET_LOGGED_USER,
                  response.data.logged_user
              )
              this.$store.commit(authenticationTypes.SET_IS_AUTHENTICATED, true)

            } else {
              this.alertMessage(response.data.message, 'error')
            }
            return response
          })
        .catch((response) => {})
      if (response.data.status) {
        await this.fetchProfile()
        // await this.identify()
        await this.Plan()

        await this.trackSignUpEvent()
        // this.$store.dispatch('trackEvent', { event: 'signed_up' })

        if (response.data.user && response.data.user.email_verify === false) {
          this.$router.push({ name: 'email_verification' })
        } else {
          // this.fetchWorkspaces()
          this.$router.push({ name: 'onboardingWorkspace' })
        }
      }
    },
    /**
     * Validate the register form
     */
    validateRegisterForm() {
      const regexp = /^[\p{L} .0-9]+$/u

      if (!this.account.password) {
        this.alertMessage('Please enter a password', 'error')
        return false
      }

      if (this.password_state === 'danger') {
        this.alertMessage(this.password_strength_message, 'error')
        return false
      }

      if (!this.account.business_name) {
        this.alertMessage('Please enter a business name', 'error')
        return false
      }

      if (this.account.business_name && this.account.business_name.length < 4) {
        this.alertMessage('The business name must be at least 4 characters.', 'error')
        return false
      }

      if (!regexp.test(this.account.business_name)) {
        this.alertMessage('Please Enter a Valid Business name, (may include letters and numbers)', 'error')
        return false
      }

      if (!this.account.business_type) {
        this.alertMessage('Please select a business type', 'error')
        return false
      }

      return true
    }
  },
}
</script>

<style scoped>
.account_verification {
  text-align: center;
}
</style>
